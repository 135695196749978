<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "SevenStrategiesToGetYouWritingAgain",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="SevenStrategiesToGetYouWritingAgain">
      <SuawParagraph
        text="Any writer, at one point of another, has had their inspiration tested on a project. However, there are many ways you can work around a motivation roadblock. Most of the time all you need is a little boost to get yourself writing again. Here are some tips to get your project back on track."
      />
      <SuawHeading level="3" content="1. Write on a 3×5 Notecard" />
      <SuawParagraph
        text="This is a visual hack for those of us who suffer from the dreaded “White Page Syndrome.” If you’re paralyzed by the fear of looking at a big blank page, try writing on a 3×5 card instead. Switching to a small card can help you gain momentum before transferring back onto the larger page — or empty document file."
      />
      <SuawHeading level="3" content="2. Reconnect With the Reason You’re Writing" />
      <SuawParagraph
        text="Knowing your purpose for writing — to inform, entertain, persuade, or inspire — is an important part of moving your piece in the right direction. Connecting with that purpose focuses your writing and keeps you from meandering too much."
      />
      <SuawHeading level="3" content="3. Move Your Body" />
      <SuawParagraph
        text="Sitting in a chair for hours on end can take its toll on your creative mind. Get up, lift some weights, dance, or do some breathing exercises. Also, while you’re at it, drink some water. This will keep you awake, invigorate your brain, and give your entire body an oxygen boost."
      />
      <SuawHeading level="3" content="4. Change Rooms" />
      <SuawParagraph
        text="Switch up your writing space. While you’re focused on the page, your brain takes in environmental cues, and those cues affect your mind. Changing rooms can help shake up your unconscious landscape."
      />
      <SuawHeading level="3" content="5. Make a List of Points You Want to Make" />
      <SuawParagraph
        text="An easy way to jump-start your brain is to make a list of the points you want to touch on in your piece. This will get your ideas out of your head, and that in itself is a great first step. Most of the time doing so helps break the project down into more manageable pieces."
      />
      <SuawHeading level="3" content="6. Get Mindful" />
      <SuawParagraph
        text="Silencing your mind has a lot of creative benefits. If you practice mindfulness, now is the time to do it. A mindfulness practice could be meditation, or it could be something more active like walking, rock climbing, or knitting."
      />
      <SuawHeading level="3" content="7. Give the Piece a Break" />
      <SuawParagraph
        text="When all else fails, set the pen down for a while. Continually bashing your head against your desk can only do so much, and it can start to sour your writing if you’re not careful. Resting your piece is sometimes the best choice. Even though it may take longer for you to finish your piece, it will be much better because of that break."
      />
      <SuawParagraph
        text="Whether you’re working on a poem, novel, or academic paper, remember that it’s always possible to get your butt back in the chair. And even though writing is a mental practice, a few physical practices can help you along the way. You’ll have a much better time and be more productive if you create a writing practice that integrates your mind and your body. Good luck and happy writing!"
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
